<template>
  <div class="success">
    <div class="success-container">
      <van-image
        width="100%"
        height="100%"
        src="image/success-bj.png"
      ></van-image>
      <div class="success-container-item">
        <div class="success-code-container">
          <div class="success-image">
            <van-image src="image/success.png"></van-image>
          </div>
          <div class="success-tip">{{msg}}</div>
          <div class="success-code" ref="qrCodeUrl"></div>
        </div>
        <div class="success-message">
          <van-cell title="姓名" :value="data.name" size="large"></van-cell>
          <van-cell title="手机号" :value="data.phone" size="large"></van-cell>
          <van-cell
            title="身份证号"
            :value="data.id_card"
            size="large"
          ></van-cell>
          <van-cell
            title="预约时间"
            :value="data.apply_date"
            size="large"
          ></van-cell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "Success",
  data() {
    return {
      msg: "",
      data: {},
    };
  },
  mounted() {
    this.msg = this.$route.query.message.msg;
    this.data = this.$route.query.message.data;
    this.creatQrCode();
  },
  methods: {
    creatQrCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.data.code, // 需要转换为二维码的内容
        width: 110,
        height: 110,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>
<style lang="scss">
.van-cell {
  padding: 10px 0;
  font-size: 13px;
  color: #333;
}

.van-field__control {
  color: #333;
}

.success-container {
  width: 345px;
  height: 459px;
  background: #eee;
  border-radius: 6px;
  box-shadow: 0px 0px 28px 7px rgba(0, 0, 0, 0.13);
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -172.5px;
  margin-top: -230px;
}
.success-container-item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.success-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 270px;
}

.success-image {
  width: 45px;
  height: 45px;
  margin-bottom: 15px;
}

.success-tip {
  color: #333;
  font-size: 16px;
  margin-bottom: 28px;
  font-weight: 600;
}

.success-code {
  width: 110px;
  height: 110px;
}

.success-message {
  padding: 9px 13px 0;
}
.success-code {
  display: inline-block;
  img {
    width: 110px;
    height: 110px;
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
  }
}
.van-cell--large .van-cell__title {
  font-size: 13px;
}
.van-cell__value {
  color: #666 !important;
}
</style>
